<template>
	<div class="home-page">
		<Head />
		<div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
		<div>
			<div class="steps-cell-enter ">
				<p>{{article.title}}</p>				
			</div>
			<Article :article="article.article"></Article>
			<!-- <text class="gui-form-label">自定义</text>
			<gui-editor ref="guieditor"></gui-editor> -->
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import Article from '@/components/article.vue'
	import {mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			Article
		},
		data() {
			return {
				swiperOptionTop: {
					loop: true,
					autoplay: true,
					loopedSlides: 1,
				},	
				// lang:'',
				
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(this.current){
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}else{
					return {items:[]}
				}
			},
			slide(){
				var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				return slide[0].pictures.split(',');
			},
			article(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				return itemlist[0]
			},
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// langchange(data){
			// 	this.lang = data;
			// },
		}
	}
</script>
