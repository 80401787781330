var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gui-editor-show gui-border-box",style:({
	paddingLeft:_vm.padding+'rpx', 
	paddingRight:_vm.padding+'rpx', 
	width:'750rpx'
})},_vm._l((_vm.article),function(item,index){return _c('div',{key:index},[(item.type == 'txt')?_c('text',{staticClass:"gui-block-text",style:(_vm.textStyle),attrs:{"decode":true,"selectable":true,"user-select":true}},[_vm._v(_vm._s(item.content))]):_vm._e(),(item.type == 'center')?_c('text',{staticClass:"gui-block-text gui-text-center",style:(_vm.centerStyle),attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'img')?_c('div',{staticClass:"gui-img-in",attrs:{"data-url":item.content}},[_c('gui-image',{attrs:{"src":item.content,"height":0,"borderRadius":_vm.imgRadius,"width":(750-_vm.padding*2)}})],1):(
		item.type == 'quote' || item.type == 'pre'
		)?_c('text',{staticClass:"gui-block-text",style:(_vm.quoteStyle),attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'strong')?_c('text',{staticClass:"gui-block-text gui-bold",style:(_vm.strongStyle),attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'link')?_c('div',[_c('gui-link',{attrs:{"url":item.content,"title":item.content}})],1):(item.type == 'spline')?_c('text',{staticClass:"gui-block-text gui-text-center",style:(_vm.splineStyle),attrs:{"selectable":true,"user-select":true}},[_vm._v("● ● ●")]):(item.type == 'h1')?_c('text',{staticClass:"gui-block-text gui-h1",attrs:{"decode":true,"selectable":true,"user-select":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'h2')?_c('text',{staticClass:"gui-block-text gui-h2",attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'h3')?_c('text',{staticClass:"gui-block-text gui-h3",attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'h4')?_c('text',{staticClass:"gui-block-text gui-h4",attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'h5')?_c('text',{staticClass:"gui-block-text gui-h5",attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):(item.type == 'h6')?_c('text',{staticClass:"gui-block-text gui-h6",attrs:{"selectable":true,"user-select":true,"decode":true}},[_vm._v(_vm._s(item.content))]):_vm._e(),(item.type == 'video')?_c('div',[_c('video',{style:({width:(750-_vm.padding*2)+'rpx'}),attrs:{"src":item.content,"controls":""}})]):_vm._e(),_c('div',{style:({height:_vm.itemMargin})})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }